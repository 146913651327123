@tailwind base
@tailwind components
@tailwind utilities

:root
  // heights
  --bottom-nav-height: 64px
  --button-height: 48px
  --drawer-collapsed-height: 220px
  --drawer-expanded-height: 520px

  // radius
  --border-radius: 12px

  // colors
  --color-primary: #300096
  --color-secondary: #E7DBFF
  --color-ternary: #7749d2
  --color-selected: #9D8AC3
  --color-danger:#ED4B4B
  --color-white: #fff
  --color-black: #0C0B02
  --color-gray-dark: #C5C5C5
  --color-gray-2: #8F8F8F
  --color-gray-3: #646464
  --color-gray-light: #F6F6F6
  --color-success: #00A40B

  // z-indexes
  --z-index-drawer: 10
  --z-index-bottom-nav: 20
  --z-index-status-popup: 30
  --z-index-general-modals: 40

  // font sizes
  --font-size-xxs: 10px
  --font-size-xs: 12px
  --font-size-sm: 14px
  --font-size-md: 16px
  --font-size-lg: 20px
  --font-size-xl: 24px
  --font-size-xxl: 32px

html,
body
  height: 100%
  width: 100vw
  padding: 0
  margin: 0

#__next
  height: 100%
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif


a 
  color: inherit
  text-decoration: none

* 
  box-sizing: border-box


a[href^="http://maps.google.com/maps"]
  display: none !important

a[href^="https://maps.google.com/maps"]
  display: none !important

.gmnoprint a,
.gmnoprint span,
.gm-style-cc 
    display: none

.gmnoprint div 
    background: none !important


#nprogress
  .spinner
    display: none !important

.mapboxgl-user-location-accuracy-circle
  pointer-events: none !important

.react-tel-input
  padding: 0 !important

  // .form-control
  
  .flag-dropdown
    border-radius: 12px !important
    outline: none !important
    border: none !important
    
  .selected-flag
    border-radius: 12px !important
    width: 60px !important
    display: flex !important
    justify-content: center !important
    padding: 0 !important
    background-color: var(--color-gray-light) !important
  

#hm_hyperModalContentWrapper
  border-radius: unset !important

#hyper-modal-wrapper_component_id
  z-index: var(--z-index-general-modals)
  border-radius: unset !important

  .hm_hyperModalContentWrapper
    border-radius: unset !important
    z-index: var(--z-index-general-modals)
    height: 100%
    width: 100%


.phone-container
  margin: 12px 0 !important
  display: flex
  
  input
    flex: 1
    height: 56px !important
    border-radius: 12px !important
    background-color: var(--color-gray-light) !important
    border: none !important
    font-size: 16px !important
    margin-left: 72px !important
    padding-left: 24px !important


.transition-drawer
  transition: max-height 0.5s ease-in-out
  max-height: var(--drawer-collapsed-height)

.transition-drawer-opened
  transition: max-height 0.5s ease-in-out
  max-height: var(--drawer-expanded-height)

    /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar
  display: none


/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar
  -ms-overflow-style: none
  scrollbar-width: none
